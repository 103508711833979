<template>
  <a-modal
    title="修改密码"
    :width="800"
    :visible="visible"
    :confirmLoading="loading"
    :keyboard="false"
    :maskClosable="false"
    @ok="
      () => {
        $emit('ok')
      }
    "
    @cancel="
      () => {
        $emit('cancel')
      }
    "
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-form-item label="旧密码">
          <a-input-password
            v-decorator="[
              'oldPwd',
              {
                rules: [{ required: true, message: '请输入旧密码' }]
              }
            ]"
          />
        </a-form-item>

        <a-form-item label="新密码">
          <a-input-password
            v-decorator="[
              'newPwd',
              {
                rules: [
                  { required: true, message: '请输入新密码' },
                ]
              }
            ]"
          />
        </a-form-item>

        <a-form-item label="确认密码">
          <a-input-password
            v-decorator="[
              'reNewPwd',
              {
                rules: [
                  { required: true, message: '请输入确认密码' },
                  {
                    validator: compareToFirstPassword
                  }
                ]
              }
            ]"
          />
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'

// 表单字段
const fields = ['oldPwd', 'newPwd', 'reNewPwd']

export default {
  name: 'ModifyPwd',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data() {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      form: this.$form.createForm(this)
    }
  },
  created() {
    console.log('custom modal created')

    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))

    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  },
  methods: {
    compareToFirstPassword(rule, value, callback) {
      const form = this.form
      if (value && value !== form.getFieldValue('newPwd')) {
        callback('确认密码与新密码不一致')
      } else {
        callback()
      }
    }
  }
}
</script>
