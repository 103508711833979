export const buttonAduits = {
    'add': '新增',
    'edit': '编辑',
    'view': '详情',
    'del': '删除',
    'copy': '复制',
    'aduit': '审核',
    'stop': '停招',
    'stopAduit': '停招审核',
    'export': '导出',
    'import': '导入',
    'qrcode': '二维码',
    'setRecommendPrice': '设置推荐费',
    'train': '增加报名是否参加报名培训',
    'addApplication': '添加报名',
    'recommed': '推荐人才',
    'followRecord': '跟进记录',
    'blank': '加入黑名单',
    'cencelBlank': '取消加黑',
    'restoreWhite': '恢复白名单',
    'blankAduit': '加入黑名单审核',
    'startDepart': '发起离职',
    'entryJob': '补充入职',
    'checkIn': '入住',
    'checkOut': '退宿',
    'enterUnion': '已入',
    'expense': '报销总额',
    'report': '申报',
    'publish': '发布',
    'recommendConfirmSend': '确认已发',
    'settlementApply': '开票申请',
    'settlementPayment': '已回款',
    'settlementInvoiceEnd': '已开',
    'assignSaler': '分配销售',
    'transform': '转化',
    'switch': '启用/禁用',
    'addSignPerson': '新增签署人',
    'submit': '提交',
    'log': '日志',
    'portrait': '画像'
}
