/**
 * 人才推荐费相关相关
 */
import tuijianfei from '@/assets/icons/tuijianfei.svg?inline'
import { RouteView } from '@/layouts'

const TalentRecommendModule = [
  {
    path: '/talent-recommend-module',
    name: 'talent-recommend-module',
    redirect: '/talent-recommend-module/recruit-account-list',
    component: RouteView,
    meta: { title: '推荐费', keepAlive: false, icon: tuijianfei, permission: ['dashboard'] },
    children: [
      {
        path: '/talent-recommend-module/tableList',
        name: 'talent-recommend-module',
        component: () => import('@/views/talent-recommend-module/list/TableList'),
        meta: { title: '推荐费列表', keepAlive: false, permission: ['dashboard'] }
      },
      {
        hidden: true,
        path: '/talent-recommend-module/detail',
        name: 'talent-recommend-module-detail',
        component: () => import('@/views/talent-recommend-module/detail/index'),
        meta: { title: '推荐费详情', keepAlive: false, permission: ['dashboard'] }
      }
    ]
  }
]

export default TalentRecommendModule
