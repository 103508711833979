/**
 * 结算相关
 */
import jiesuan from '@/assets/icons/jiesuan.svg?inline'
import { RouteView } from '@/layouts'

const SettlementModule = [
  {
    path: '/settlement-module',
    name: 'settlement-module',
    redirect: '/settlement-module/settlement-list',
    component: RouteView,
    meta: { title: '结算', keepAlive: false, icon: jiesuan, permission: ['dashboard'] },
    children: [
      {
        path: '/settlement-module/settlement-list',
        name: 'SettlementTableList',
        component: () => import('@/views/settlement-module/settlement-list/TableList'),
        meta: { title: '结算列表', keepAlive: false, permission: ['dashboard'] }
      },
      {
        hidden: true,
        path: '/settlement-module/settlement-detail',
        name: 'SettlementDetail',
        component: () => import('@/views/settlement-module/settlement-list/detail'),
        meta: { title: '结算详情', keepAlive: false, permission: ['dashboard'] }
      },
      {
        path: '/settlement-module/invoice-record-list',
        name: 'InvoiceRecordTableList',
        component: () => import('@/views/settlement-module/invoice-record-list/TableList'),
        meta: { title: '开票记录', keepAlive: false, permission: ['dashboard'] }
      },
      {
        hidden: true,
        path: '/settlement-module/invoice-record-detail',
        name: 'InvoiceRecordDetail',
        component: () => import('@/views/settlement-module/invoice-record-list/detail'),
        meta: { title: '开票记录详情', keepAlive: false, permission: ['dashboard'] }
      }
    ]
  }
]

export default SettlementModule
