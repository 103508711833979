import { asyncRouterMap, constantRouterMap } from '@/config/router.config'
import cloneDeep from 'lodash.clonedeep'
import { buttonAduits } from '@/config/buttonAduits'
import router, { resetRouter } from '@/router'
import Dashboard from '@/router/module/Dashboard'
import { bxAnaalyse } from '@/core/icons'
import { RouteView } from '@/layouts'
import gongzuotai from '@/assets/icons/gongzuotai.svg?inline'
/**
 * 过滤账户是否拥有某一个权限，并将菜单从加载列表移除
 *
 * @param permission
 * @param route
 * @returns {boolean}
 */
function hasPermission(permission, route) {
  if (route.meta && route.meta.permission) {
    if (permission === undefined) {
      return false
    }
    let flag = false
    for (let i = 0, len = permission.length; i < len; i++) {
      flag = route.meta.permission.includes(permission[i])
      if (flag) {
        return true
      }
    }
    return false
  }
  return true
}

/**
 * 单账户多角色时，使用该方法可过滤角色不存在的菜单
 *
 * @param roles
 * @param route
 * @returns {*}
 */
// eslint-disable-next-line
function hasRole(roles, route) {
  if (route.meta && route.meta.roles) {
    return route.meta.roles.includes(roles.id)
  } else {
    return true
  }
}

function filterAsyncRouter(routerMap, menuList) {
  const pathArr = menuList.map(item => item.jumpUrl)
  const accessedRouters = routerMap.filter(route => {
    if (pathArr.includes(route.path) || route.path == '/' || route.path === '*' || route.hidden) {
      if (route.children && route.children.length) {
        route.children = filterAsyncRouter(route.children, menuList)
      }
      return true
    }
    return false
  })
  return accessedRouters
}

function filterRouter(routerMap, menuList) {
  const accessedRouters = routerMap.filter((route, index) => {
    // if ()
  })
}

function formatMenuList(menuList) {
  const actions = []
  const newMenu = []

  console.log('menuList>>>>>>>>>>>>', menuList)
  menuList.forEach(item => {
    newMenu.push({
      id: item.id,
      jumpUrl: item.jumpUrl,
      menuName: item.menuName
    })
    if (item.operationAuthority && item.operationAuthority !== 'null') {
      console.log(item.operationAuthority)
      actions.push(...item.operationAuthority.split(',').map(_item => _item.replace(item.id, item.jumpUrl)))
    }
    if (item.subMenu && item.subMenu.length) {
      const o = formatMenuList(item.subMenu)
      newMenu.push(...o.newMenu)
      actions.push(...o.actions)
    }
  })
  return { newMenu, actions }
}

const permission = {
  state: {
    routers: constantRouterMap,
    addRouters: []
  },
  mutations: {
    SET_ROUTERS: (state, routers) => {
      state.addRouters = routers
      state.routers = constantRouterMap.concat(routers)
    }
  },
  actions: {
    GenerateRoutes({ commit }) {
      return new Promise(resolve => {
        console.log('asyncRouterMap>>>>', asyncRouterMap)
        const routerMap = cloneDeep(asyncRouterMap)
        console.log('routerMap>>>>>>>>>>>>>>>>', routerMap)
        const menuList = localStorage.getItem('menu') ? JSON.parse(localStorage.getItem('menu')) : []

        const { newMenu, actions } = formatMenuList(menuList)
        console.log('107>>>>>>>>>>>')
        console.log(newMenu, actions)
        localStorage.setItem('buttonAduits', actions)
        // const accessedRouters = filterAsyncRouter(routerMap, role)
        const accessedRouters = filterAsyncRouter(routerMap, newMenu)
        // const accessedRouters = routerMap
        console.log(accessedRouters, 'accessedRouters')

        accessedRouters?.[0]?.children.unshift({
          path: '/dashboard',
          name: 'dashboard',
          redirect: '/dashboard/workplace',
          component: RouteView,
          meta: {
            title: '工作台',
            keepAlive: true,
            icon: gongzuotai,
            permission: ['dashboard']
          }
        })

        console.log('accessedRouters>>>>>>>>>>>>>>>>', accessedRouters)

        commit('SET_ROUTERS', [...accessedRouters, ...Dashboard])
        resolve()
      })
    }
  }
}

export default permission
