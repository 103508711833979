import storage from 'store'
import expirePlugin from 'store/plugins/expire'
import { login, getInfo, logout } from '@/api/login'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { flatTree, welcome } from '@/utils/util'
import { string } from 'mockjs2/src/mock/random/basic'
import { stringify } from 'qs'
import store from '@/store'
import { asyncRouterMap, constantRouterMap } from '@/config/router.config'

storage.addPlugin(expirePlugin)
const user = {
  state: {
    token: '',
    name: '',
    welcome: '',
    avatar: '',
    roles: [],
    info: {},
    userInfo: {},
    menu: []
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, { name, welcome }) => {
      state.name = name
      state.welcome = welcome
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_INFO: (state, info) => {
      state.info = info
    },
    SET_USERINFO: (state, info) => {
      state.userInfo = info
    },
    SET_MENU: (state, info) => {
      state.menu = info
    }
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      console.log('userInfo>>>>>>>>', userInfo)
      return new Promise((resolve, reject) => {
        /* this.$http
          .post('/admin/login', stringify(userInfo))
          .then(response => {
            console.log('response>>>>>>>>>>', response)

            const result = response
            storage.set(ACCESS_TOKEN, result.token, new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
            commit('SET_TOKEN', result.token)
            resolve()
          })
          .catch(error => {
            console.log('error', error)
            reject(error)
          }) */
        login(userInfo)
          .then(response => {
            const result = response.userinfo
            storage.set(ACCESS_TOKEN, result.token, new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
            storage.set('menu', response.menu, new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
            commit('SET_TOKEN', result.token)

            commit('SET_INFO', result)
            commit('SET_USERINFO', result)
            commit('SET_NAME', { name: result.username, welcome: welcome() })
            commit('SET_AVATAR', result.avatar)
            commit('SET_MENU', response.menu)
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 获取用户信息
    GetInfo({ commit }) {
      return new Promise((resolve, reject) => {
        console.log('83>>>>>>>>>')
        console.log(store.getters.userInfo)
        console.log(store.getters.menu)
        console.log('asyncRouterMap>>>>>>>>>>>>>>>>>', asyncRouterMap)
        const userinfo = store.getters.userInfo
        // 请求后端获取用户信息 /api/user/info
        const response = {
          id: userinfo.id,
          name: userinfo.fullName,
          username: userinfo.username,
          password: '',
          avatar: userinfo.headImg,
          status: userinfo.state,
          telephone: userinfo.phone,
          lastLoginIp: null,
          lastLoginTime: null,
          creatorId: 'admin',
          createTime: null,
          merchantCode: null,
          deleted: 0,
          roleId: userinfo.roleId,
          role: {
            id: userinfo.roleId,
            name: userinfo.roleName,
            describe: '',
            status: userinfo.state,
            creatorId: 'admin',
            createTime: null,
            deleted: 0,
            /*      permissions: [
              {
                roleId: 'admin',
                permissionId: 'dashboard',
                permissionName: '仪表盘111',
                actions:
                  '[{"action":"add","defaultCheck":false,"describe":"新增"},{"action":"query","defaultCheck":false,"describe":"查询"},{"action":"get","defaultCheck":false,"describe":"详情"},{"action":"update","defaultCheck":false,"describe":"修改"},{"action":"delete","defaultCheck":false,"describe":"删除"}]',
                actionEntitySet: [
                  {
                    action: 'add',
                    describe: '新增',
                    defaultCheck: false
                  },
                  {
                    action: 'query',
                    describe: '查询',
                    defaultCheck: false
                  },
                  {
                    action: 'get',
                    describe: '详情',
                    defaultCheck: false
                  },
                  {
                    action: 'update',
                    describe: '修改',
                    defaultCheck: false
                  },
                  {
                    action: 'delete',
                    describe: '删除',
                    defaultCheck: false
                  }
                ],
                actionList: ['add', 'query', 'get', 'update', 'delete'],
                dataAccess: null
              },
              {
                roleId: 'admin',
                permissionId: 'exception',
                permissionName: '异常页面权限',
                actions:
                  '[{"action":"add","defaultCheck":false,"describe":"新增"},{"action":"query","defaultCheck":false,"describe":"查询"},{"action":"get","defaultCheck":false,"describe":"详情"},{"action":"update","defaultCheck":false,"describe":"修改"},{"action":"delete","defaultCheck":false,"describe":"删除"}]',
                actionEntitySet: [
                  {
                    action: 'add',
                    describe: '新增',
                    defaultCheck: false
                  },
                  {
                    action: 'query',
                    describe: '查询',
                    defaultCheck: false
                  },
                  {
                    action: 'get',
                    describe: '详情',
                    defaultCheck: false
                  },
                  {
                    action: 'update',
                    describe: '修改',
                    defaultCheck: false
                  },
                  {
                    action: 'delete',
                    describe: '删除',
                    defaultCheck: false
                  }
                ],
                actionList: ['add', 'query', 'get', 'update', 'delete'],
                dataAccess: null
              },
              {
                roleId: 'admin',
                permissionId: 'result',
                permissionName: '结果权限',
                actions:
                  '[{"action":"add","defaultCheck":false,"describe":"新增"},{"action":"query","defaultCheck":false,"describe":"查询"},{"action":"get","defaultCheck":false,"describe":"详情"},{"action":"update","defaultCheck":false,"describe":"修改"},{"action":"delete","defaultCheck":false,"describe":"删除"}]',
                actionEntitySet: [
                  {
                    action: 'add',
                    describe: '新增',
                    defaultCheck: false
                  },
                  {
                    action: 'query',
                    describe: '查询',
                    defaultCheck: false
                  },
                  {
                    action: 'get',
                    describe: '详情',
                    defaultCheck: false
                  },
                  {
                    action: 'update',
                    describe: '修改',
                    defaultCheck: false
                  },
                  {
                    action: 'delete',
                    describe: '删除',
                    defaultCheck: false
                  }
                ],
                actionList: ['add', 'query', 'get', 'update', 'delete'],
                dataAccess: null
              },
              {
                roleId: 'admin',
                permissionId: 'profile',
                permissionName: '详细页权限',
                actions:
                  '[{"action":"add","defaultCheck":false,"describe":"新增"},{"action":"query","defaultCheck":false,"describe":"查询"},{"action":"get","defaultCheck":false,"describe":"详情"},{"action":"update","defaultCheck":false,"describe":"修改"},{"action":"delete","defaultCheck":false,"describe":"删除"}]',
                actionEntitySet: [
                  {
                    action: 'add',
                    describe: '新增',
                    defaultCheck: false
                  },
                  {
                    action: 'query',
                    describe: '查询',
                    defaultCheck: false
                  },
                  {
                    action: 'get',
                    describe: '详情',
                    defaultCheck: false
                  },
                  {
                    action: 'update',
                    describe: '修改',
                    defaultCheck: false
                  },
                  {
                    action: 'delete',
                    describe: '删除',
                    defaultCheck: false
                  }
                ],
                actionList: ['add', 'query', 'get', 'update', 'delete'],
                dataAccess: null
              },
              {
                roleId: 'admin',
                permissionId: 'table',
                permissionName: '表格权限',
                actions:
                  '[{"action":"add","defaultCheck":false,"describe":"新增"},{"action":"import","defaultCheck":false,"describe":"导入"},{"action":"get","defaultCheck":false,"describe":"详情"},{"action":"update","defaultCheck":false,"describe":"修改"}]',
                actionEntitySet: [
                  {
                    action: 'add',
                    describe: '新增',
                    defaultCheck: false
                  },
                  {
                    action: 'import',
                    describe: '导入',
                    defaultCheck: false
                  },
                  {
                    action: 'get',
                    describe: '详情',
                    defaultCheck: false
                  },
                  {
                    action: 'update',
                    describe: '修改',
                    defaultCheck: false
                  }
                ],
                actionList: ['add', 'import', 'get', 'update'],
                dataAccess: null
              },
              {
                roleId: 'admin',
                permissionId: 'form',
                permissionName: '表单权限',
                actions:
                  '[{"action":"add","defaultCheck":false,"describe":"新增"},{"action":"get","defaultCheck":false,"describe":"详情"},{"action":"query","defaultCheck":false,"describe":"查询"},{"action":"update","defaultCheck":false,"describe":"修改"},{"action":"delete","defaultCheck":false,"describe":"删除"}]',
                actionEntitySet: [
                  {
                    action: 'add',
                    describe: '新增',
                    defaultCheck: false
                  },
                  {
                    action: 'get',
                    describe: '详情',
                    defaultCheck: false
                  },
                  {
                    action: 'query',
                    describe: '查询',
                    defaultCheck: false
                  },
                  {
                    action: 'update',
                    describe: '修改',
                    defaultCheck: false
                  },
                  {
                    action: 'delete',
                    describe: '删除',
                    defaultCheck: false
                  }
                ],
                actionList: ['add', 'get', 'query', 'update', 'delete'],
                dataAccess: null
              },
              {
                roleId: 'admin',
                permissionId: 'order',
                permissionName: '订单管理',
                actions:
                  '[{"action":"add","defaultCheck":false,"describe":"新增"},{"action":"query","defaultCheck":false,"describe":"查询"},{"action":"get","defaultCheck":false,"describe":"详情"},{"action":"update","defaultCheck":false,"describe":"修改"},{"action":"delete","defaultCheck":false,"describe":"删除"}]',
                actionEntitySet: [
                  {
                    action: 'add',
                    describe: '新增',
                    defaultCheck: false
                  },
                  {
                    action: 'query',
                    describe: '查询',
                    defaultCheck: false
                  },
                  {
                    action: 'get',
                    describe: '详情',
                    defaultCheck: false
                  },
                  {
                    action: 'update',
                    describe: '修改',
                    defaultCheck: false
                  },
                  {
                    action: 'delete',
                    describe: '删除',
                    defaultCheck: false
                  }
                ],
                actionList: ['add', 'query', 'get', 'update', 'delete'],
                dataAccess: null
              },
              {
                roleId: 'admin',
                permissionId: 'permission',
                permissionName: '权限管理',
                actions:
                  '[{"action":"add","defaultCheck":false,"describe":"新增"},{"action":"get","defaultCheck":false,"describe":"详情"},{"action":"update","defaultCheck":false,"describe":"修改"},{"action":"delete","defaultCheck":false,"describe":"删除"}]',
                actionEntitySet: [
                  {
                    action: 'add',
                    describe: '新增',
                    defaultCheck: false
                  },
                  {
                    action: 'get',
                    describe: '详情',
                    defaultCheck: false
                  },
                  {
                    action: 'update',
                    describe: '修改',
                    defaultCheck: false
                  },
                  {
                    action: 'delete',
                    describe: '删除',
                    defaultCheck: false
                  }
                ],
                actionList: ['add', 'get', 'update', 'delete'],
                dataAccess: null
              },
              {
                roleId: 'admin',
                permissionId: 'role',
                permissionName: '角色管理',
                actions:
                  '[{"action":"add","defaultCheck":false,"describe":"新增"},{"action":"get","defaultCheck":false,"describe":"详情"},{"action":"update","defaultCheck":false,"describe":"修改"},{"action":"delete","defaultCheck":false,"describe":"删除"}]',
                actionEntitySet: [
                  {
                    action: 'add',
                    describe: '新增',
                    defaultCheck: false
                  },
                  {
                    action: 'get',
                    describe: '详情',
                    defaultCheck: false
                  },
                  {
                    action: 'update',
                    describe: '修改',
                    defaultCheck: false
                  },
                  {
                    action: 'delete',
                    describe: '删除',
                    defaultCheck: false
                  }
                ],
                actionList: ['add', 'get', 'update', 'delete'],
                dataAccess: null
              },
              {
                roleId: 'admin',
                permissionId: 'table',
                permissionName: '桌子管理',
                actions:
                  '[{"action":"add","defaultCheck":false,"describe":"新增"},{"action":"get","defaultCheck":false,"describe":"详情"},{"action":"query","defaultCheck":false,"describe":"查询"},{"action":"update","defaultCheck":false,"describe":"修改"},{"action":"delete","defaultCheck":false,"describe":"删除"}]',
                actionEntitySet: [
                  {
                    action: 'add',
                    describe: '新增',
                    defaultCheck: false
                  },
                  {
                    action: 'get',
                    describe: '详情',
                    defaultCheck: false
                  },
                  {
                    action: 'query',
                    describe: '查询',
                    defaultCheck: false
                  },
                  {
                    action: 'update',
                    describe: '修改',
                    defaultCheck: false
                  },
                  {
                    action: 'delete',
                    describe: '删除',
                    defaultCheck: false
                  }
                ],
                actionList: ['add', 'get', 'query', 'update', 'delete'],
                dataAccess: null
              },
              {
                roleId: 'admin',
                permissionId: 'user',
                permissionName: '用户管理',
                actions:
                  '[{"action":"add","defaultCheck":false,"describe":"新增"},{"action":"import","defaultCheck":false,"describe":"导入"},{"action":"get","defaultCheck":false,"describe":"详情"},{"action":"update","defaultCheck":false,"describe":"修改"},{"action":"delete","defaultCheck":false,"describe":"删除"},{"action":"export","defaultCheck":false,"describe":"导出"}]',
                actionEntitySet: [
                  {
                    action: 'add',
                    describe: '新增',
                    defaultCheck: false
                  },
                  {
                    action: 'import',
                    describe: '导入',
                    defaultCheck: false
                  },
                  {
                    action: 'get',
                    describe: '详情',
                    defaultCheck: false
                  },
                  {
                    action: 'update',
                    describe: '修改',
                    defaultCheck: false
                  },
                  {
                    action: 'delete',
                    describe: '删除',
                    defaultCheck: false
                  },
                  {
                    action: 'export',
                    describe: '导出',
                    defaultCheck: false
                  }
                ],
                actionList: ['add', 'import', 'get', 'update', 'delete', 'export'],
                dataAccess: null
              },
              {
                roleId: 'admin',
                permissionId: 'support',
                permissionName: '超级模块',
                actions:
                  '[{"action":"add","defaultCheck":false,"describe":"新增"},{"action":"import","defaultCheck":false,"describe":"导入"},{"action":"get","defaultCheck":false,"describe":"详情"},{"action":"update","defaultCheck":false,"describe":"修改"},{"action":"delete","defaultCheck":false,"describe":"删除"},{"action":"export","defaultCheck":false,"describe":"导出"}]',
                actionEntitySet: [
                  {
                    action: 'add',
                    describe: '新增',
                    defaultCheck: false
                  },
                  {
                    action: 'import',
                    describe: '导入',
                    defaultCheck: false
                  },
                  {
                    action: 'get',
                    describe: '详情',
                    defaultCheck: false
                  },
                  {
                    action: 'update',
                    describe: '修改',
                    defaultCheck: false
                  },
                  {
                    action: 'delete',
                    describe: '删除',
                    defaultCheck: false
                  },
                  {
                    action: 'export',
                    describe: '导出',
                    defaultCheck: false
                  }
                ],
                actionList: ['add', 'import', 'get', 'update', 'delete', 'export'],
                dataAccess: null
              }
            ], */
            permissions: flatTree(asyncRouterMap),
            permissionList: flatTree(asyncRouterMap).map(item => item.name)
          }
        }
        const result = response
        if (result.role && result.role.permissions.length > 0) {
          const role = { ...result.role }
          // role.permissions = result.role.permissions.map(permission => {
          //   const per = {
          //     ...permission
          //     // actionList: (permission.actionEntitySet || {}).map(item => item.action)
          //   }
          //   return per
          // })
          // role.permissionList = role.permissions.map(permission => {
          //   return permission.name
          // })
          // 覆盖响应体的 role, 供下游使用
          result.role = role

          commit('SET_ROLES', role)

          // 下游
          resolve(result)
        } else {
          reject(new Error('getInfo: roles must be a non-null array !'))
        }

        /* getInfo()
          .then(response => {
            const result = response
            if (result.role && result.role.permissions.length > 0) {
              const role = { ...result.role }
              role.permissions = result.role.permissions.map(permission => {
                const per = {
                  ...permission,
                  actionList: (permission.actionEntitySet || {}).map(item => item.action)
                }
                return per
              })
              role.permissionList = role.permissions.map(permission => {
                return permission.permissionId
              })
              // 覆盖响应体的 role, 供下游使用
              result.role = role

              commit('SET_ROLES', role)
              commit('SET_INFO', result)
              commit('SET_NAME', { name: result.name, welcome: welcome() })
              commit('SET_AVATAR', result.avatar)
              // 下游
              resolve(result)
            } else {
              reject(new Error('getInfo: roles must be a non-null array !'))
            }
          })
          .catch(error => {
            reject(error)
          }) */
      })
    },

    // 登出
    Logout({ commit, state }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        commit('SET_ROLES', [])
        commit('SET_INFO', [])
        commit('SET_USERINFO', [])
        commit('SET_ROUTERS', [])
        storage.remove(ACCESS_TOKEN)
        storage.clearAll()
        resolve()
        /* logout(state.token)
          .then(() => {
            commit('SET_TOKEN', '')
            commit('SET_ROLES', [])
            commit('SET_INFO', [])
            commit('SET_USERINFO', [])
            commit('SET_ROUTERS', [])
            storage.remove(ACCESS_TOKEN)
            storage.clearAll()

            resolve()
          })
          .catch(err => {
            console.log('logout fail:', err)
            // resolve()
          })
          .finally(() => {}) */
      })
    }
  }
}

export default user
