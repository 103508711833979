/**
 * 客户管理相关
 */
import { RouteView } from '@/layouts'

import kehu from '@/assets/icons/kehu.svg?inline'

const CustomerManager = [
  {
    path: '/customer-manager',
    name: 'customer-manager',
    redirect: '/customer-manager/customer-list',
    component: RouteView,
    meta: { title: '客户管理', keepAlive: false, icon: kehu, permission: ['dashboard'] },
    children: [
      {
        path: '/customer-manager/customer-list',
        name: 'customer-list',
        component: () => import('@/views/customer-manager/list/TableList'),
        meta: { title: '客户列表', keepAlive: false, permission: ['dashboard'] }
      },
      {
        hidden: true,
        path: '/customer-manager/customer-edit/:id?',
        name: 'customer-edit',
        component: () => import('@/views/customer-manager/form/index'),
        meta: { title: '新增/编辑客户', keepAlive: false, permission: ['dashboard'] }
      },
      {
        hidden: true,
        path: '/customer-manager/customer-workplace/:id?',
        name: 'customer-workplace',
        component: () => import('@/views/customer-manager/list/workplace'),
        meta: { title: '业绩概览', keepAlive: false, permission: ['dashboard'] }
      }
    ]
  }
]

export default CustomerManager
