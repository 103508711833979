import { RouteView } from '@/layouts'
import zhaopinxuqiu from '@/assets/icons/zhaopinxuqiu.svg?inline'
export default [
    {
        path: '/recruitment-demand',
        name: 'recruitment-demand',
        redirect: '/recruitment-demand/requirement-list',
        component: RouteView,
        meta: { title: '招聘需求', keepAlive: false, icon: zhaopinxuqiu, permission: ['dashboard'] },
        children: [
          {
            path: '/recruitment-demand/requirement-list',
            name: 'requirement-list',
            component: () => import('@/views/recruitment-demand/requirement-list'),
            meta: { title: '需求列表', keepAlive: false, permission: ['dashboard'] }
          },
          {
            path: '/recruitment-demand/requirement-release',
            name: 'requirement-release',
            component: () => import('@/views/recruitment-demand/release'),
            hidden: true,
            meta: { title: '发布需求', keepAlive: false, permission: ['dashboard'] }
          },
          {
            path: '/recruitment-demand/recomerd-number-detail',
            name: 'recomerd-number-detail',
            component: () => import('@/views/recruitment-demand/recomerdNumberDetail'),
            hidden: true,
            meta: { title: '报名人员', keepAlive: false, permission: ['dashboard'] }
          },
          // {
          //   path: '/recruitment-demand/sync-resume',
          //   name: 'sync-resume',
          //   component: () => import('@/views/recruitment-demand/sync-resume'),
          //   meta: { title: '同步简历', keepAlive: false, permission: ['dashboard'] }
          // },
          {
            path: '/recruitment-demand/min-resume',
            name: 'min-resume',
            component: () => import('@/views/recruitment-demand/min-resume'),
            meta: { title: '小程序简历', keepAlive: false, permission: ['dashboard'] }
          },
          {
            path: '/recruitment-demand/resume-detail',
            name: 'resume-detail',
            hidden: true,
            component: () => import('@/views/recruitment-demand/resume/index'),
            meta: { title: '简历', keepAlive: false, permission: ['dashboard'] }
          }
        ]
      }
]
