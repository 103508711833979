import { bxAnaalyse } from '@/core/icons'
import { BasicLayout, RouteView } from '@/layouts'

const Dashboard = [
  {
    path: '/dashboard',
    name: 'dashboard',
    redirect: '/dashboard/workplace',
    component: BasicLayout,
    meta: { title: '工作台', keepAlive: true, icon: bxAnaalyse, permission: ['public'] },
    children: [
      // {
      //   path: '/dashboard/analysis/:pageNo([1-9]\\d*)?',
      //   name: 'Analysis',
      //   component: () => import('@/views/dashboard/Analysis'),
      //   meta: { title: 'menu.dashboard.analysis', keepAlive: false, permission: ['dashboard'] }
      // },
      // 外部链接
      // {
      //   path: 'https://www.baidu.com/',
      //   name: 'Monitor',
      //   meta: { title: 'menu.dashboard.monitor', target: '_blank' }
      // },
      {
        path: '/dashboard/workplace',
        name: 'Workplace',
        component: () => import('@/views/dashboard/Workplace'),
        meta: { title: '工作台', keepAlive: true, permission: ['dashboard'] }
      }
    ]
  }
]

export default Dashboard
