import { RouteView } from '@/layouts'
import rencaiku from '@/assets/icons/rencaiku.svg?inline'
export default [
  {
    path: '/talent-pool',
    name: 'talent-pool',
    redirect: '/talent-pool/talent-list',
    component: RouteView,
    meta: { title: '人才库', keepAlive: false, icon: rencaiku, permission: ['dashboard'] },
    children: [
      {
        path: '/talent-pool/talent-list',
        name: 'talent-list',
        component: () => import('@/views/talent-pool/talent-list'),
        meta: { title: '人才列表', keepAlive: false, permission: ['dashboard'] }
      }
    ]
  }
]
