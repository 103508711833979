import { RouteView } from '@/layouts'
import shebaoxinzi from '@/assets/icons/shebaoxinzi.svg?inline'

export default [
  {
    path: '/security-salary',
    name: 'security-salary',
    redirect: '/security-salary/security-list',
    component: RouteView,
    meta: { title: '社保薪资', keepAlive: false, icon: shebaoxinzi, permission: ['dashboard'] },
    children: [
      {
        path: '/security-salary/security-list',
        name: 'security-list',
        component: () => import('@/views/security-salary/security-list'),
        meta: { title: '社会保险', keepAlive: false, permission: ['dashboard'] }
      },
      {
        path: '/security-salary/security-detail',
        name: 'security-detail',
        hidden: true,
        component: () => import('@/views/security-salary/security-detail'),
        meta: { title: '社保', keepAlive: false, permission: ['dashboard'] }
      },
      {
        path: '/security-salary/accumulation-fund',
        name: 'accumulation-fund',
        component: () => import('@/views/security-salary/accumulation-fund'),
        meta: { title: '公积金', keepAlive: false, permission: ['dashboard'] }
      },
      {
        path: '/security-salary/accident-insurance',
        name: 'accident-insurance',
        component: () => import('@/views/security-salary/accident-insurance'),
        meta: { title: '商业险', keepAlive: false, permission: ['dashboard'] }
      },
      {
        path: '/security-salary/work-injury-reporting',
        name: 'work-injury-reporting',
        component: () => import('@/views/security-salary/work-injury-reporting'),
        meta: { title: '工伤提报', keepAlive: false, permission: ['dashboard'] }
      },
      {
        path: '/security-salary/reporting-detail',
        name: 'reporting-detail',
        component: () => import('@/views/security-salary/reporting-detail'),
        hidden: true,
        meta: { title: '详情', keepAlive: false, permission: ['dashboard'] }
      },
      {
        path: '/security-salary/accident-insurance-reporting',
        name: 'accident-insurance-reporting',
        component: () => import('@/views/security-salary/accident-insurance-reporting'),
        meta: { title: '意外险提报', keepAlive: false, permission: ['dashboard'] }
      },
      {
        path: '/security-salary/childbearing-fund-reporting',
        name: 'childbearing-fund-reporting',
        component: () => import('@/views/security-salary/childbearing-fund-reporting'),
        meta: { title: '生育金提报', keepAlive: false, permission: ['dashboard'] }
      },
      {
        path: '/security-salary/salary-list',
        name: 'salary-list',
        component: () => import('@/views/security-salary/salary-list'),
        meta: { title: '薪资', keepAlive: false, permission: ['dashboard'] }
      },
      {
        path: '/security-salary/salary-detail',
        name: 'salary-detail',
        hidden: true,
        component: () => import('@/views/security-salary/salary-detail'),
        meta: { title: '薪资复核', keepAlive: false, permission: ['dashboard'] }
      }
    ]
  }
]
