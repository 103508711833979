/**
 * 数据分析相关
 */
import shujufenxi from '@/assets/icons/shujufenxi.svg?inline'
import { RouteView } from '@/layouts'

const DataAnalysis = [
  {
    path: '/data-analysis-module',
    name: 'data-analysis-module',
    redirect: '/data-analysis-module/customer-portrait-list',
    component: RouteView,
    meta: { title: '数据分析', keepAlive: false, icon: shujufenxi, permission: ['dashboard'] },
    children: [
      {
        path: '/data-analysis-module/customer-portrait-list',
        name: 'customer-portrait-list',
        component: () => import('@/views/data-analysis-module/customer-portrait-list/TableList'),
        meta: { title: '客户画像', keepAlive: false, permission: ['dashboard'] }
      },
      {
        hidden: true,
        path: '/data-analysis-module/customer-portrait-chart/:id?',
        name: 'customer-portrait-chart',
        component: () => import('@/views/data-analysis-module/customer-portrait-list/index'),
        meta: { title: '客户画像图表页面', keepAlive: false, permission: ['dashboard'] }
      },
      {
        path: '/data-analysis-module/customer-statistics',
        name: 'customer-statistics',
        component: () => import('@/views/data-analysis-module/customer-statistics/index'),
        meta: { title: '客户统计', keepAlive: false, permission: ['dashboard'] }
      },
      {
        path: '/data-analysis-module/personnel-statistics',
        name: 'personnel-statistics',
        component: () => import('@/views/data-analysis-module/personnel-statistics/index'),
        meta: { title: '人员统计', keepAlive: false, permission: ['dashboard'] }
      },
      {
        path: '/data-analysis-module/recruitment',
        name: 'recruitment',
        component: () => import('@/views/data-analysis-module/recruitment/index'),
        meta: { title: '招聘需求', keepAlive: false, permission: ['dashboard'] }
      },
      {
        path: '/data-analysis-module/referral',
        name: 'referral',
        component: () => import('@/views/data-analysis-module/referral/index'),
        meta: { title: '推荐费', keepAlive: false, permission: ['dashboard'] }
      },
      {
        path: '/data-analysis-module/settle',
        name: 'settle',
        component: () => import('@/views/data-analysis-module/settle/index'),
        meta: { title: '结算', keepAlive: false, permission: ['dashboard'] }
      },
      {
        path: '/data-analysis-module/net-achievement',
        name: 'net-achievement',
        component: () => import('@/views/data-analysis-module/net-achievement/index'),
        meta: { title: '网招业绩', keepAlive: false, permission: ['dashboard'] }
      },
      {
        path: '/data-analysis-module/poor-indicator',
        name: 'poor-indicator',
        component: () => import('@/views/data-analysis-module/poor-indicator/index'),
        meta: { title: '不良指标', keepAlive: false, permission: ['dashboard'] }
      }
    ]
  }
]

export default DataAnalysis
