/**
 * 系统角色权限相关
 */

import { RouteView } from '@/layouts'
import xitong from '@/assets/icons/xitong.svg?inline'

const SystemRoleSetting = [
  {
    path: '/system-role-setting',
    name: 'system-role-setting',
    redirect: '/system-role-setting/recruit-account-list',
    component: RouteView,
    meta: { title: '系统', keepAlive: false, icon: xitong, permission: ['dashboard'] },
    children: [
      // {
      //   path: '/system-role-setting/recruit-account-list',
      //   name: 'recruit-account-list',
      //   component: () => import('@/views/system-role-setting/recruit-account/TableList'),
      //   meta: { title: '账号', keepAlive: false, permission: ['dashboard'] }
      // },
      {
        path: '/system-role-setting/account',
        name: 'account',
        component: () => import('@/views/system-role-setting/account/TableList'),
        meta: { title: '账号', keepAlive: false, permission: ['dashboard'] }
      },
      {
        path: '/system-role-setting/role',
        name: 'role',
        component: () => import('@/views/system-role-setting/role/TableList'),
        meta: { title: '角色', keepAlive: false, permission: ['dashboard'] }
      },
      {
        path: '/system-role-setting/log',
        name: 'log',
        component: () => import('@/views/system-role-setting/log/TableList'),
        meta: { title: '日志', keepAlive: false, permission: ['dashboard'] }
      }
    ]
  }
]

export default SystemRoleSetting
