import Vue from 'vue'
import router from '@/router'

Vue.directive('authority', {
    inserted(el, binding) {
        const buttonAduits = localStorage.getItem('buttonAduits')
        const buttonAduitsArr = buttonAduits ? buttonAduits.split(',') : []
        const path = router.currentRoute.path
        const value = binding.value
        if (!buttonAduitsArr.includes(`${path}:${value}`) && value) {
            el.style.display = 'none'
        }
        // console.log(binding)
        // const perssionList = localStorage.getItem('perss')
        // el.style.display = 'none'
    },
    update(el, binding, vnode, prevVnode) {
        const buttonAduits = localStorage.getItem('buttonAduits')
        const buttonAduitsArr = buttonAduits ? buttonAduits.split(',') : []
        const path = router.currentRoute.path
        const value = binding.value
        // console.log(value, `${path}:${value}`)
        if (!buttonAduitsArr.includes(`${path}:${value}`) && value) {
            el.style.display = 'none'
        } else {
            el.style.display = 'inline-block'
        }
    }
})
