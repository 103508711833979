import { RouteView } from '@/layouts'
import jichushuju from '@/assets/icons/jichushuju.svg?inline'

const basicDataSetting = [
  {
    path: '/basic-data-setting',
    name: 'basic-data-setting',
    redirect: '/basic-data-setting/recruit-account-list',
    component: RouteView,
    meta: { title: '基础数据设置', keepAlive: false, icon: jichushuju, permission: ['dashboard'] },
    children: [
      {
        path: '/basic-data-setting/recruit-post-list',
        name: 'recruit-post-list',
        component: () => import('@/views/basic-data-setting/recruit-post/TableList'),
        meta: { title: '岗位', keepAlive: false, permission: ['dashboard'] }
      },
      {
        path: '/basic-data-setting/structure-list',
        name: 'structure-list',
        component: () => import('@/views/basic-data-setting/structure/TableList'),
        meta: { title: '组织架构', keepAlive: false, permission: ['dashboard'] }
      },
      {
        path: '/basic-data-setting/talent-tag-list',
        name: 'talent-tag-list',
        component: () => import('@/views/basic-data-setting/talent-tag/TableList'),
        meta: { title: '人才标签', keepAlive: false, permission: ['dashboard'] }
      },
      {
        path: '/basic-data-setting/channel-list',
        name: 'channel-list',
        component: () => import('@/views/basic-data-setting/channel/TableList'),
        meta: { title: '渠道', keepAlive: false, permission: ['dashboard'] }
      },
      {
        path: '/basic-data-setting/electron-signature-list',
        name: 'electron-signature-list',
        component: () => import('@/views/basic-data-setting/electron-signature/TableList'),
        meta: { title: '电子签', keepAlive: false, permission: ['dashboard'] }
      },
      {
        path: '/basic-data-setting/recruit-account-list',
        name: 'recruit-account-list',
        component: () => import('@/views/basic-data-setting/recruit-account/TableList'),
        meta: { title: '招聘账户', keepAlive: false, permission: ['dashboard'] }
      },
      {
        path: '/basic-data-setting/approvaltime-list',
        name: 'approvaltime-list',
        component: () => import('@/views/basic-data-setting/approvaltime/TableList'),
        meta: { title: '审批时效', keepAlive: false, permission: ['dashboard'] }
      },
      {
        path: '/basic-data-setting/insurance-list',
        name: 'insurance-list',
        component: () => import('@/views/basic-data-setting/insurance/TableList'),
        meta: { title: '社会保险', keepAlive: false, permission: ['dashboard'] }
      },
      {
        hidden: true,
        path: '/basic-data-setting/insurance/social-insurance-edit',
        name: 'social-insurance-edit',
        component: () => import('@/views/basic-data-setting/insurance/social-insurance-edit'),
        meta: { title: '添加社保', keepAlive: false, permission: ['dashboard'] }
      },
      {
        hidden: true,
        path: '/basic-data-setting/insurance/social-insurance-log',
        name: 'social-insurance-log',
        component: () => import('@/views/basic-data-setting/insurance/log/social-insurance-log'),
        meta: { title: '社保日志', keepAlive: false, permission: ['dashboard'] }
      },
      {
        hidden: true,
        path: '/basic-data-setting/insurance/accumulation-log',
        name: 'accumulation-log',
        component: () => import('@/views/basic-data-setting/insurance/log/accumulation-log'),
        meta: { title: '公积金日志', keepAlive: false, permission: ['dashboard'] }
      },
      {
        hidden: true,
        path: '/basic-data-setting/insurance/accident-insurance-log',
        name: 'accident-insurance-log',
        component: () => import('@/views/basic-data-setting/insurance/log/accident-insurance-log'),
        meta: { title: '意外险日志', keepAlive: false, permission: ['dashboard'] }
      },
      {
        hidden: true,
        path: '/basic-data-setting/insurance/accumulation-edit',
        name: 'accumulation-edit',
        component: () => import('@/views/basic-data-setting/insurance/accumulation-edit'),
        meta: { title: '添加公积金', keepAlive: false, permission: ['dashboard'] }
      },
      {
        hidden: true,
        path: '/basic-data-setting/insurance/accident-insurance-edit',
        name: 'accident-insurance-edit',
        component: () => import('@/views/basic-data-setting/insurance/accident-insurance-edit'),
        meta: { title: '添加意外险', keepAlive: false, permission: ['dashboard'] }
      },
      {
        path: '/basic-data-setting/welcome',
        name: 'welcome',
        component: () => import('@/views/basic-data-setting/welcome/index'),
        meta: { title: '人才端欢迎页', keepAlive: false, permission: ['dashboard'] }
      }
    ]
  }
]

export default basicDataSetting
