import { RouteView } from '@/layouts'
import waifuyuangong from '@/assets/icons/waifuyuangong.svg?inline'
export default [
  {
    path: '/exocaruncle-person',
    name: 'exocaruncle-person',
    redirect: '/exocaruncle-person/exocaruncle-list',
    component: RouteView,
    meta: { title: '外阜员工', keepAlive: false, icon: waifuyuangong, permission: ['dashboard'] },
    children: [
      {
        path: '/exocaruncle-person/exocaruncle-list',
        name: 'exocaruncle-list',
        component: () => import('@/views/exocaruncle-person/exocaruncle-list'),
        meta: { title: '员工列表', keepAlive: false, permission: ['dashboard'] }
      },
      {
        path: '/exocaruncle-person/exocaruncle-picture',
        name: 'exocaruncle-picture',
        component: () => import('@/views/exocaruncle-person/exocaruncle-picture'),
        meta: { title: '员工证照', keepAlive: false, permission: ['dashboard'] }
      },
      {
        path: '/exocaruncle-person/person-detail',
        name: 'person-detail',
        hidden: true,
        component: () => import('@/views/recruitment-demand/resume/index'),
        meta: { title: '员工详情', keepAlive: false, permission: ['dashboard'] }
      },
      {
        path: '/exocaruncle-person/accommodation-registration',
        name: 'accommodation-registration',
        component: () => import('@/views/exocaruncle-person/accommodation-registration'),
        meta: { title: '住宿登记', keepAlive: false, permission: ['dashboard'] }
      },
      {
        path: '/exocaruncle-person/payroll-card',
        name: 'payroll-card',
        component: () => import('@/views/exocaruncle-person/payroll-card'),
        meta: { title: '工资卡', keepAlive: false, permission: ['dashboard'] }
      },
      {
        path: '/exocaruncle-person/union-list',
        name: 'union-list',
        component: () => import('@/views/exocaruncle-person/union-list'),
        meta: { title: '工会', keepAlive: false, permission: ['dashboard'] }
      },
      {
        path: '/exocaruncle-person/certificate-list',
        name: 'certificate-list',
        component: () => import('@/views/exocaruncle-person/certificate-list'),
        meta: { title: '证明开具', keepAlive: false, permission: ['dashboard'] }
      },
      {
        path: '/exocaruncle-person/leave-list',
        name: 'leave-list',
        component: () => import('@/views/exocaruncle-person/leave-list'),
        meta: { title: '请假', keepAlive: false, permission: ['dashboard'] }
      },
      {
        path: '/exocaruncle-person/notice-list',
        name: 'notice-list',
        component: () => import('@/views/exocaruncle-person/notice-list'),
        meta: { title: '通知公告', keepAlive: false, permission: ['dashboard'] }
      },
      {
        path: '/exocaruncle-person/notice-add',
        name: 'notice-add',
        hidden: true,
        component: () => import('@/views/exocaruncle-person/notice-add'),
        meta: { title: '新增公告', keepAlive: false, permission: ['dashboard'] }
      },
      {
        path: '/exocaruncle-person/case-library',
        name: 'case-library',
        component: () => import('@/views/exocaruncle-person/case-library'),
        meta: { title: '案例库', keepAlive: false, permission: ['dashboard'] }
      },
      {
        path: '/exocaruncle-person/case-add',
        name: 'case-add',
        hidden: true,
        component: () => import('@/views/exocaruncle-person/case-add'),
        meta: { title: '新增案例', keepAlive: false, permission: ['dashboard'] }
      },
      {
        path: '/exocaruncle-person/seal-application',
        name: 'seal-application',
        component: () => import('@/views/exocaruncle-person/seal-application'),
        meta: { title: '印章申请', keepAlive: false, permission: ['dashboard'] }
      },
      {
        path: '/exocaruncle-person/seal-add',
        name: 'seal-add',
        hidden: true,
        component: () => import('@/views/exocaruncle-person/seal-add'),
        meta: { title: '新增印章申请', keepAlive: false, permission: ['dashboard'] }
      }
    ]
  }
]
