// eslint-disable-next-line
import { UserLayout, BasicLayout, BlankLayout } from '@/layouts'
import { bxAnaalyse } from '@/core/icons'
import basicDataSetting from '@/router/module/basicDataSetting'
import systemRoleSetting from '@/router/module/SystemRoleSetting'
import TalentRecommendModule from '@/router/module/TalentRecommendModule'
import CustomerManager from '@/router/module/CustomerManager'
import SettlementModule from '@/router/module/SettlementModule'
import DataAnalysis from '@/router/module/DataAnalysis'
import recruitmentDemand from '@/router/module/recruitment-demand'
import talentPool from '@/router/module/talent-pool'
import exocarunclePerson from '@/router/module/exocaruncle-person'
import securitySalary from '@/router/module/security-salary'
import Dashboard from '@/router/module/Dashboard'

const RouteView = {
  name: 'RouteView',
  render: h => h('router-view')
}

export const asyncRouterMap = [
  {
    path: '/',
    name: 'index',
    component: BasicLayout,
    meta: { title: 'menu.home' },
    redirect: '/dashboard',
    children: [
      // dashboard
      ...Dashboard,
      ...CustomerManager,
      ...recruitmentDemand,
      ...talentPool,
      ...exocarunclePerson,
      ...securitySalary,
      ...basicDataSetting,
      ...systemRoleSetting,
      ...TalentRecommendModule,
      ...SettlementModule,
        ...DataAnalysis
    ]
  },
  {
    path: '*',
    redirect: '/404',
    hidden: true
  }
]

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: '/user',
    component: UserLayout,
    redirect: '/user/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Login')
      },
      {
        path: 'register',
        name: 'register',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Register')
      },
      {
        path: 'register-result',
        name: 'registerResult',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/RegisterResult')
      },
      {
        path: 'recover',
        name: 'recover',
        component: undefined
      }
    ]
  },

  {
    path: '/404',
    component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/404')
  }
]
