<template>
  <div :class="wrpCls">
    <avatar-dropdown :menu="showMenu" :current-user="currentUser" :class="prefixCls" @modifyPwd="handleModifyPwd" />
    <!--    <select-lang :class="prefixCls" />-->
    <ModifyPwd
      ref="modifyModal"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleCancel"
      @ok="handleOk"
    />
  </div>
</template>

<script>
import AvatarDropdown from './AvatarDropdown'
import SelectLang from '@/components/SelectLang'
import { mapGetters } from 'vuex'
import ModifyPwd from '@/views/basic-data-setting/recruit-account/modules/ModifyPwd.vue'
import { stringify } from 'qs'
import md5 from 'md5'

export default {
  name: 'RightContent',
  components: {
    ModifyPwd,
    AvatarDropdown,
    SelectLang
  },
  props: {
    prefixCls: {
      type: String,
      default: 'ant-pro-global-header-index-action'
    },
    isMobile: {
      type: Boolean,
      default: () => false
    },
    topMenu: {
      type: Boolean,
      required: true
    },
    theme: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showMenu: true,
      currentUser: {},
      visible: false,
      confirmLoading: false,
      mdl: null
    }
  },
  computed: {
    ...mapGetters(['users']),
    wrpCls() {
      return {
        'ant-pro-global-header-index-right': true,
        [`ant-pro-global-header-index-${this.isMobile || !this.topMenu ? 'light' : this.theme}`]: true
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.currentUser = {
        name: this.users.username
      }
    }, 1500)
  },
  methods: {
    handleModifyPwd() {
      this.visible = true
    },
    handleCancel() {
      this.visible = false
    },
    handleOk() {
      const form = this.$refs.modifyModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          Object.assign(values, {
            oldPwd: md5(values.oldPwd),
            newPwd: md5(values.newPwd),
            reNewPwd: void 0,
            id: this.users.id,
            type: 1
          })

          this.$http
            .post('/admin/updatePwd', stringify(values))
            .then(res => {
              this.confirmLoading = false
              this.visible = false
              // 重置表单数据
              form.resetFields()
              this.$message.info('修改密码成功，请重新登录')
              this.$store.dispatch('Logout').then(() => {
                this.$router.push({ name: 'login' })
              })
            })
            .catch(() => this.$message.info('修改密码失败'))
        } else {
          this.confirmLoading = false
        }
      })
    }
  }
}
</script>
