<template>
  <a-config-provider >
    <div id="app">
      <a-spin v-if="showLoading" :spinning="this.$store.state.loading" tip="正在导出..." >
        <router-view />
      </a-spin>
      <router-view v-else />
    </div>
  </a-config-provider>
</template>

<script>
import { domTitle, setDocumentTitle } from '@/utils/domUtil'
import { i18nRender } from '@/locales'

export default {
  data() {
    return {
    }
  },
  computed: {
    showLoading() {
      return this.$route.name !== 'login'
    },
    locale() {
      // 只是为了切换语言时，更新标题
      const { title } = this.$route.meta
      title && (setDocumentTitle(`${i18nRender(title)} - ${domTitle}`))

      return this.$i18n.getLocaleMessage(this.$store.getters.lang).antLocale
    }
  }
}
</script>
